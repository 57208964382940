export const REDUX_STATES = {
    DEFAULT_KEY: "listing" + new Date().getTime(),
    DEFAULT_DROPDOWN_KEY: "dropdown" + new Date().getTime(),
    DEFAULT_ADD_KEY: "add" + new Date().getTime(),
    DEFAULT_VIEW_KEY: "view" + new Date().getTime(),
    DEFAULT_EDIT_KEY: "edit" + new Date().getTime(),
    DEFAULT_DEL_KEY: "delete" + new Date().getTime(),
    DEFAULT_FILTERS_KEY: "filter" + new Date().getTime(),
    DEFAULT_SELECTED_KEY: "selected" + new Date().getTime(),
    LOADING: "_loading",
    ERROR: "_error",
    ADD: "_add",
    VIEW: "_view",
    EDIT: "_edit",
    FILTERS: "_filters",
    SELECTED: "_selected",
    CLIENT_DETAIL: "clientDetail",
    CLIENT_PROJECTS: "clientProjects",
    PROJECTS: "projects",
    PROJECT_DETAIL: "projectDetail",
    ALLOCATION_DETAIL: "allocationDetail",
    COMPANY_NAMES: "company_names",
    CLIENT: "clients",
    CLIENT_ISSUES: "clientsIssues",
    GET_CLIENT_LIST: "getClientList",
    EMPLOYEES: "employees",
    EMPLOYEES_DELETE: "employeesDelete",
    EMPLOYEES_DETAIL: "employeesDetail",
    EMPLOYEE_SKILLSET: "employeeSkillset",
    EMPLOYEES_DETAIL_DOCUMENT: "employeesDetailDocument",
    TIMESHEET: "timesheet",
    ASSETS_DETAIL: "assetsDetail",
    EMPLOYEE_DETAIL: "employeeDetail",
    WORKERS: "worker",
    ALLOCATIONS: "allocation",
    ALLOCATION_DETAIL: "allocationDetail",
    SKILLS: "skill",
    CURRENT_ALLOCATIONS: "currentAllocation",
    PAST_ALLOCATIONS: "pastAllocation",
    FUTURE_ALLOCATIONS: "futureAllocation",
    EMPLOYEE_SKILLS: "employeeSkills",
    PERSONAL_SKILLS: "personalSkills",
    PROJECT_ISSUES: "projectIssues",
    PROJECT_ISSUE_TIME_LOGS: "projectIssueTimeLogs",
    INVOICE_PER_PROJECT: "invoicePerProject",
    DOWNLOAD_COMPLETE_CSV: "downloadCompleteCSV",
    ALL_PROJECTS: "allProjects",
    INVOICING_PROJECTS: "invoicingProjects",
    CHART_PROJECTS: "chartProject",
    CHART_PROJECT_DETAIL: "chartProjectDetail",
    WORKER_BY_ID: "workerById",
    ASSETS: "assets",
    ASSETS: "assets",
    CONTACT: "contact",
    BILLABLE_HOURS: "billableHours",
    XERO_AUTHORIZATION: "xeroAuthorization",
    XERO_RESPONSE_HANDLER: "xeroResponseHandler",
    XERO_GENERATE_INVOICE: "xeroGenerateInvoice",
    EMPLOYEES_DROPDOWN: "employeesDropdown",
    SKILLS_DROPDOWN: "skillsDropdown",
    CLIENTS_DROPDOWN: "clientsDropdown",
    PROJECTS_DROPDOWN: "projectsDropdown",
    MANAGER_DROPDOWN: "managerDropdown",
    DASHBOARD_DETAILED_CHART: "dashboardDetailedChart",
    INVOICING_HEADER: "invoicingHeader",
    PROJECT_DATA: "projectData",
    HOURS_LOGGED_DATA: "hoursLoggedData",
    EMPLOYEE_HOURS_RATIO: "employeeHoursRatio",
    PROJECT_FOLDER_DATA: "projectFolderData",
    PROJECT_STATUS_DATA: "projectStatusData",
    PROJECT_OVER_MONTH: "projectOverMonth",
    PORJECT_PROJECTION: "projectProjection",
    RESOURCE_AND_BUDGET_DATA: "resourceAndBudgetData",
    GET_RESOURCE_FORECAST: "getResourceForecast",
    PROJECTS_DATA: "projectsData",
    PROJECT_COUNT: "projectCount",
    HOURS_LOGGED: "hoursLogged",
    CLOSED_TICKETS_DATA: "closedTicketsData",
    HEAD_COUNT_DATA: "headCountData",
    EMPLOYEE_DROPDOWN: "employeeDropdown",
    PROJECTS_DROPDOWN: "projectsDropdown",
    GET_AUTHORIZE_XERO: "getAuthorizeXero",
    RESPONSE_HANDLER: "responseHandler",
    GENERATE_XERO_INVOICE: "generateXeroInvoice",
    PROJECTS_LIST: "projectsList",
    PROJECTS_DELETE: "projectsDelete",
    PROJECTS_UPDATE: "projectsUpdate",
    GET_SKILL_NAMES: "getSkillNames",
    ADD_SKILLS: "addSkills",
    ADD_CLIENTS: "addClietns",
    RESOURCE_FORECAST: "resourceForecast",
    EMPLOYEE_LIST: "employeeList",
    EMPLOYEE_DELETE: "employeeDelete",
    GET_EMPLOYEE_SKILL_LIST: "getSkillList",
    STATUS_BOARD_PROJECTS: "statusBoardProjects",
    STATUS_BOARD_UPDATE_PROJECTS: "statusBoardUpdateProjects",
    STATUS_BOARD_DELETE_PROJECTS: "statusBoardDeleteProjects",
    STATUS_BOARD_SINGLE_PROJECT: "statusBoardSingleProject",
    CLIENTS_DROPDOWN: "clientsDropdown",
    CLIENT_BY_ID: "clientById",
    DELIVERY_LEAD_DROPDOWN: "deliveryLeadDropdown",
    TECH_LEAD_DROPDOWN: "techLeadDropdown",
    DOCUMENT_UPLOAD: "documentUpload",
    POSITIONS: "positionsData",
    SKILLS: "skills",
    SKILLS_DROPDOWN: "skillsDropdown",
    SKILLS_LIST: "skillsList",
    COMMENTS: "comments",
    PROJECTS_DETAILS: "projectsDetails",
    EMPLOYEE_SKILLSET_UPDATE: "employeeSkillsetUpdate",
    SPACES: "spaces",
    AWS_SERVERS: "awsServers",
    DISCOVERY_SESSION: "discoverySession",
    DISCOVERY_SESSION_LIST: "discoverySessionList",
    DISCOVERY_SESSION_ADD_UPDATE: "discoverySessionAddUpdate",
    DISCOVERY_SESSION_ADD_UPDATE_STEP_1: "discoverySessionAddUpdateStep1",
    DISCOVERY_SESSION_ADD_UPDATE_STEP_2: "discoverySessionAddUpdateStep2",
    DISCOVERY_SESSION_ADD_UPDATE_STEP_3: "discoverySessionAddUpdateStep3",
    DISCOVERY_SESSION_ADD_UPDATE_STEP_4: "discoverySessionAddUpdateStep4",
    DISCOVERY_SESSION_ROLES: "discoverySessionRoles",
    PARKING_LOTS: "parkingLots",
    CONSTRAINTS: "constraints",
    PRIORITIES: "priorities",
    CLIENT_FEEDBACK: "clientFeedback",
    DELETE_CLIENT_FEEDBACK: "deleteClientFeedback",
    SPRINT_SESSION: "sprintSession",
    USER_STORIES_BULK_UPDATE: "userStoriesBulkUpdate",
    PROJECT_RATE: "projectrate",
    ADD_RATE: "addrate",
    SPRINT_SESSION: "sprintSession",
    DISCOVERY_SESSION_TIME_ESTIMATIONS: "discoverySessionTimeEstimations",
    GET_FEEDBACK_LIST: "getFeedbackList",
    FEEDBACK_ADD_UPDATE: "feedbackAddUpdate"
};
