export const API_URLS = {

    STATUSBOARD: "/statusboard",
    GRAPH_PROJECTS_EMPLOYEE_DETAIL: "/dashboard/get_projects_employees_details/",
    RESET_PASSWORD: "/reset_password/",
    PASSWORD_RESET_CONFIRM: "/password_reset_confirm/",
    XERO_RESPONSE_HANDLER: "/xero/response_handler/",
    ADMIN_RESET_PASSWORD: "/employes/admin_reset_password",
    INVOICING_HEADER: "/projects/invoicing_header/",

    // New Sunset APIs
    GET_PROJECT_DATA: "/db/project_data/",
    GET_TOP_PROJECTION: "/db/current_month_projection/",
    GET_HEAD_COUNT: "/db/head_count/",
    GET_EMPLOYEE_HOURS_RATIO: "/db/employee_hours_ratio/",
    GET_TOP_PROJECT_PROJECTION: "/db/top_project_projection/",
    GET_PROJECTS_STATUS_STATS: "/db/projects_status_stats/",
    GET_PROJECT_MOM: "/db/month_over_month/",
    GET_RESOURCE_AND_BUDGETS: "/resourcesandbudgets/list/",
    GET_PROJECT: "/projects/list",
    PROJECT_COUNT: "/projects/get_project_count/",
    GET_CLOSED_TICKETS: "/db/closed_tickets_last_day/",
    GET_HEAD_COUNT: "/db/head_count/",


    PROJECTS_DROPDOWN_INVOICING: "/fetch_clickup_projects/",

    INVOICING_GET_EMPLOYEES: "/employee_project_time/",
    RESPONSE_HANDLER_API: "/response_handler/",
    GET_SKILL_NAMES: "/skilltypes/list/",
    ADD_SKILLS: "/skills/create/",



    SKILL_LIST: "/skills/list/",
    EMPLOYEE_SKILL: "/employee_skills/",
    UPDATE_EMPLOYEE_SKILLS: "/employee_skills/",
    ASSETS: "/employes/allocate_asset/",
    DOWNLOAD_COMPLETE_CSV_PROJECT: "/projects/download_complete_csv/",

    DASHBOARD_CHART_PROJECTS: "/dashboard/",
    DASHBOARD_DETAILED_CHART: "/dashboard/dashboard_detailed_graph/",
    TIMESHEET: "/timesheet/",
    EMPLOYEE_BY_ID: "/employes/?user=:id",
    CREATE_JIRA_TICKET: "/clients/create_jira_ticket/",
    UPDATE_BILLABLE_HOURS: "/projects/billable_hours/",
    XERO_AUTHORIZATION: "/xero/authorize_xero/",
    XERO_GENERATE_INVOICE: "/xero/authorize_xero/generate_invoice/",



    GET_EMPLOYEE_HOURS_RATIO: "/db/employee_hours_ratio/",
    GET_TOP_PROJECT_PROJECTION: "/db/top_project_projection/",
    GET_PROJECTS_STATUS_STATS: "/db/projects_status_stats/",
    GET_PROJECT_MOM: "/db/month_over_month/",
    GET_RESOURCE_AND_BUDGETS: "/resourcesandbudgets/list/",
    GET_PROJECT: "/projects/list",
    PROJECT_COUNT: "/projects/get_project_count/",
    GET_HOURS_LOGGED: "/db/hours_logged_this_month/",
    GET_HEAD_COUNT: "/db/head_count/",

    EMPLOYEE_DROPDOWN_INVOICING: "/fetch_clickup_employees/",
    PROJECT_LIST: "/projects/list/",
    INVOICING_GET_PROJECTS: "/InvoicingHeaderView/",
    GET_AUTHORIZE_XERO: "/authorize_xero/",
    GENERATE_INVOICE_API: "/authorize_xero/generate_invoice/",
    RESOURCE_FORECAST: "/resources_forecast/",

    // APIS NEW SUNSET
    LOGIN: "/login/",
    CHANGE_PASSWORD: "/change-password/",
    REFRESH_TOKEN: "/refresh-token/",
    CLIENTS: "/clients/",
    PROJECTS: "/projects/",
    EMPLOYEES: "/employees/",
    MANAGE_DOCS: "/manage-documents/",
    STATUS_BOARD_PROJECTS: "/status_board_projects/",
    STATUS_BOARD_PROJECTS_UPDATE: "/status_board_projects/:id/",
    STATUS_BOARD_PROJECTS_DELETE: "/status_board_projects/:id/",
    STATUS_BOARD_SINGLE_PROJECT: "/status_board_projects/:id/",
    POSITIONS: "/positions/",
    SKILLS: "/skills/",
    COMMENTS: "/comments/",
    SPACES: "/spaces/",
    AWS_SERVERS: "/aws/instances/",
    START_AWS_SERVERS: "/aws/instances/start/",
    STOP_AWS_SERVERS: "/aws/instances/stop/",
    DISCOVERIES: "/discoveries/",
    DISCOVER_ROLES: "/discovery/:id/roles/",
    PARKING_LOTS: "/discovery/parking-lot-ideas/",
    CONSTRAINTS: "/discovery/constraints/",
    CLIENT_FEEDBACK: "/discovery/client-feedback/",
    PRIORITIES: "/discovery/:id/grouped-user-stories/",
    SPRINT_SESSION_PROJECT: "/project/sprints/",
    USER_STORIES_BULK_UPDATE: "/discovery/user-stories/bulk-update/",
    ADD_RATE: "/project/rate-cards/",
    SPRINT_SESSION_PROJECT: "/project/sprints/",
    DISCOVERY_SESSION: "/discovery/discovery-session/",
    DISCOVERY_SESSION_TIME_ESTIMATIONS: "/discovery/discovery-time-estimation/",
    FEEDBACK: "/feedbacks/"

};
