import { UploadOutlined } from '@ant-design/icons';

// Antd
import { Button, Upload, Form } from 'antd';

// Localization
import LOCALIZATION from "@timetrack/common/src/services/LocalizationService";

function ImageUploadDefault({
  placeholder, label, action,
  listType, valuePropName,
  fileList, notwrapInForm,
  maxCount, required, validator,
  name, validateTrigger, onChange,
  type, accept, multiple
}) {
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  let rules = [
    { required: required, message: LOCALIZATION.REQUIRED },
  ];

  // Custom Validations
  !!validator && rules.push({ validator });

  const imageUpload = (
    <Upload
      placeholder={placeholder || label}
      action={action}
      fileList={fileList}
      onChange={onChange}
      type={type}
      accept={accept}
      maxCount={maxCount}
      customRequest={dummyRequest}
      listType={listType}
      multiple={multiple}
    >
      <Button type='primary' icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
  return !!notwrapInForm ? (
    <>{imageUpload}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
      valuePropName={valuePropName}
      getValueFromEvent={(e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      }}
    >
      {imageUpload}
    </Form.Item>
  );
};
export default ImageUploadDefault;