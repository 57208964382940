import React from 'react';

// antd
import { Col, Row } from 'antd';

const Layout = ({ value, borderColor }) => {

    const getRandomColor = () => {
        return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
    };

    return (
        <Col style={{ borderColor: borderColor }} span={24} className="form-control UserStoryCard">
            {value || ""}
        </Col>
    );
};

export default Layout;
