/*
  Date Picker
*/

import React from 'react';

// Antd
import { Form } from 'antd';

import DatePicker from './DatePickerComponent';

// import { CaretDownOutlined } from '@ant-design/icons';

// Localization
import LOCALIZATION from '@timetrack/common/src/services/LocalizationService';

// import { SPACE_VALIDATIOR } from '../../../../constants/Patterns';

// Style
// import './style.scss';s

function Picker({
  name, label, placeholder,
  readOnly, defaultValue, format,
  onChange, disabled, required,
  onBlur, validator, validateTrigger,
  disabledDate, notwrapInForm, type
}) {

  // Rules
  let rules = [
    { required: required, message: LOCALIZATION.REQUIRED },
    // {
    //   pattern: SPACE_VALIDATIOR,
    //   message: LOCALIZATION.SPACE_VALIDATION
    // }
  ];

  // Custom Validations
  !!validator && rules.push({ validator });

  const DatePickerInput = (
    <DatePicker
      placeholder={placeholder || label}
      onChange={onChange || null}
      defaultValue={defaultValue}
      disabled={disabled}
      onBlur={onBlur}
      picker={type}
      readOnly={readOnly}
      format={format || 'dd/MM/y'}
      disabledDate={disabledDate}
    // suffixIcon={<CaretDownOutlined />}
    />
  );

  return (
    !!notwrapInForm ?
      <>
        {DatePickerInput}
      </> :
      <Form.Item
        name={name}
        label={label}
        validateTrigger={validateTrigger || 'onBlur'}
        rules={rules}
      >
        {DatePickerInput}
      </Form.Item>

  );
}

export default Picker;