/*
  Application Confirm Password Page
*/

import React, {  useState } from "react";
import { useHistory, useParams} from "react-router-dom";

// Antd
import {Form,Button,Input} from "antd"

// Redux
import { useDispatch } from "react-redux";

// Components
import AuthenticationBase from "..";

// Helper
import {
  errorNotification,
  successNotification,
} from "@timetrack/common/src/helpers/Notification";


// Constants
import APP_URL from "../../../constants/ApplicationUrls";
import { API_URLS } from "@timetrack/common/src/constants/ApiUrl";

//ACTIONS
import { updatePatchRequest } from "@timetrack/common/src/store/actions/CRUDAction";

// Localization
import LOCALIZATION from "@timetrack/common/src/services/LocalizationService";

function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  let formRef = React.createRef();

  const { uid, token } = useParams();

  const [loading, setloading] = useState(false);
  const onSubmit = (data) => {
    data["uid"] = uid;
    data["token"] = token;
    setloading(true);
    dispatch(
      updatePatchRequest(API_URLS.PASSWORD_RESET_CONFIRM, data, {}, {})
    ).then(
      (response) => {
        form.resetFields();
        successNotification(response.data.message);
        history.push(APP_URL.AUTH.LOGIN);
        setloading(false);
      },
      (e) => {
        errorNotification(e?.response?.data?.confirm_password);
        form.resetFields();
        setloading(false);
      }
    );
  };

  return (
    <AuthenticationBase loading={loading}>
      <div className="app-logo">
        <h1>New Password</h1>
      </div>

      <Form form={form} ref={formRef} onFinish={onSubmit} layout="vertical">
        <Form.Item
          className="form-group"
          name="password"
          label={LOCALIZATION.NEW_PASSWORD}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
          hasFeedback
        >
          <Input.Password className="form-control"/>
        </Form.Item>

        <Form.Item
          className="form-group"
          name="confirm_password"
          label={LOCALIZATION.NEW_PASSWORD_CONFIRM}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Password not match"));
              },
            }),
          ]}
        >
          <Input.Password className="form-control"/>
        </Form.Item>
        <Form.Item>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            className="mt-3"
          >
            {LOCALIZATION.CONFIRM_PASSWORD}
          </Button>
        </Form.Item>
      </Form>
    </AuthenticationBase>
  );
}

export default Login;
