import ReactNativeStorageService from './react-native';
import ReactStorageService from './react';
import { APP_TYPE } from '../../constants/General';

export default class StorageService {
    static instance = null;

    constructor(platform, storage) {
        this.service = platform === APP_TYPE.WEB ? new ReactStorageService(storage) : new ReactNativeStorageService(storage);
    }

    // Set Access Token
    setAccessToken(accessToken) {
        this.service.setAccessToken(accessToken);
    }

    // Get Access Token
    getAccessToken() {
        return this.service.getAccessToken();
    }

    // Set Refresh Token
    setRefreshToken(token) {
        this.service.setRefreshToken(token);
    }

    // Get Refresh Token
    getRefreshToken() {
        return this.service.getRefreshToken();
    }

    // Set User Info
    setUserInfo(info) {
        this.service.setUserInfo(info);
    }

    // Get User Info
    getUserInfo() {
        return this.service.getUserInfo();
    }

    // Delete login data
    deleteLoginData() {
        this.service.deleteLoginData();
    }

} 