export const LANGUAGE = {
  EN: 1,
};

export const APP_TYPE = {
  MOBILE: 1,
  WEB: 2
};

export const API_TIMEOUT = 90000;

export const NOTIFICATION_DURATION = 4;

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "TIME_TRACK_ACCESS_TOKEN",
  REFRESH_TOKEN: "TIME_TRACK_REFRESH_TOKEN",
  USER_INFO: "TIME_TRACK_USER_INFO"
};
export const LISTING_DATA = {
  FIRST_PAGE: 1,
  PAGE_SIZE: 20,
  PAGE_SIZE_INFO_BLOCK: 100,
  PAGE_SIZE_MAX_SIZE: 9999,
};

export const PAGINATION_DATA = {
  FIRST_PAGE: 1,
  PAGE_SIZE: 20,
  PAGE_SIZE_INFO_BLOCK: 100,
  PAGE_SIZE_MAX_SIZE: 9999,
};

export const SORT = {
  ASC: 'ascend',
  DESC: 'descend',
};

export const ROLES = {
  SUPER_ADMIN: "super admin",
  ADMIN: "admin",
  MANAGER: "project manager",
  EMPLOYEE: "employee",
  CLIENT: "client",
  HR: "human resources"
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SEARCH_TYPING_INTERVAL = 700;