import React, { useState } from 'react';

// antd
import { Col, Row, Form } from 'antd';

// LOCALIZATION
import LOCALIZATION from '@timetrack/common/src/services/LocalizationService';

import PriorityCard from '../../../DiscoverySession/Add/PriorityCard';

function PictureSelector({
    name, label, options,
    disabled, required, validator, validateTrigger,
    notwrapInForm, className, onChange,
    layout, isImage
}) {

    // Validation rules
    let rules = [{ required: required, message: LOCALIZATION.REQUIRED }];
    if (validator) {
        rules.push({ validator: validator });
    }

    const [selectedPicture, setSelectedPicture] = useState(null);

    const PictureList = ({ fieldValue, onChangeInternal }) => (
        <Row gutter={[16, 16]}>
            {options?.map((data, index) => (
                <Col
                    span={layout === true ? 6 : 24}
                    key={index}
                >
                    <div
                        onClick={() => {
                            if (!disabled) {
                                onChangeInternal(data?.value);
                                if (onChange) onChange(data?.value);
                            }
                        }}
                        className={`PictureSelector
                                ${fieldValue === data?.value ? 'selected-picture selectedBorder' : 'notSelectedBorder'}
                                ${disabled === data?.value ? 'notAllowed' : 'pointer'}`}
                    >
                        {isImage ? <img
                            alt={`pic-${index}`}
                            src={data?.url}
                            style={{ width: '100%', borderRadius: "9px" }}
                        /> :
                            <div className='UserStoryCardContainer'>
                                <PriorityCard
                                    value={data?.value}
                                    borderColor={data?.color}
                                />
                            </div>
                        }
                    </div>
                </Col>))}
        </Row>
    );

    if (notwrapInForm) {
        return (
            <PictureList
                fieldValue={selectedPicture}
                onChangeInternal={(value) => {
                    setSelectedPicture(value);
                    if (onChange) onChange(value);
                }}
            />
        );
    }

    return (
        <Form.Item
            name={name}
            className={className}
            label={label}
            validateTrigger={validateTrigger || 'onBlur'}
            rules={rules}
        >
            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues?.[name] !== curValues?.[name]}>
                {({ getFieldValue, setFieldsValue }) => (
                    <PictureList
                        fieldValue={getFieldValue(name)}
                        onChangeInternal={(value) => {
                            setFieldsValue({ [name]: value });
                            // Trigger validation manually
                            setTimeout(() => {
                                setFieldsValue({ [name]: value });
                            });
                        }}
                    />
                )}
            </Form.Item>
        </Form.Item>
    );
}

export default PictureSelector;