export const DATE_FORMAT = {
  YEAR_MONTH_DAY_WITHOUT_SEPARATOR: 'yyMMdd',
  MONTH_SLASH_DAY_SLASH_YEAR: 'MM/dd/yyyy',
  DAY_SLASH_MONTH_SLASH_YEAR: 'dd/MM/yyyy',
  MONTH_DAY_YEAR: 'MM-dd-yyyy',
  YEAR_MONTH_DAY: 'yyyy-MM-dd',
  SHORTMONTH_DATE: 'MMM dd',
  SHORTMONTH_DATE_YEAR: 'MMM dd, yyyy',
  SHORTMONTH_DATE_YEAR_HOUR_MIN: 'MMM dd, yyyy HH:mm',
  MONTH_SLASH_DAY_SLASH_YEAR_HOUR_MIN: 'MM/dd/yyyy HH:mm',
  MONTH_DATE: 'MMMM dd',
  MONTH_DATE_YEAR: 'MMMM dd, yyyy',
  DAYNAME_SHORTMONTH_DATE_YEAR: 'EEEE MMM dd, yyyy',
  ONLY_DAY: 'EEEE',
  WEEKNO_SHORTDAY_MONTH_SLASH_DAY_SLASH_YEAR: 'I - E - MM/dd/yyyy',
  DAY_DOT_MONTH_DOT_YEAR: 'dd.MM.yy',
  DAY_DOT_MONTH_DOT_YEAR_HOUR_MINUTE: 'dd.MM.yy HH:mm',
  DAY_SLASH_MONTH_SLASH_YEAR_HOUR_MINUTE: 'dd/MM/yyyy HH:mm',
  WEEK_NO: 'I',
  MONTH_SLASH_DAY: 'MM/dd',
  HOUR_MINUTE_12F: 'hh:mm a',
  HOUR_MINUTE_SECONDS: 'HH:mm:ss',
};