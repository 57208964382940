import React, { useState, useEffect } from "react";

// Antd
import { Row, Col, Progress, Space } from "antd";

// Localization
import LOCALIZATION from "@timetrack/common/src/services/LocalizationService";

// Images
import appLogo from "../../assets/images/auth.png";

import "./style.scss";

function Loading({ name }) {
    const [progress1, setProgress1] = useState(0);
    const [progress2, setProgress2] = useState(0);

    useEffect(() => {
        const interval1 = setInterval(() => {
            setProgress1((prevProgress) => {
                const newProgress = prevProgress + 1;
                return newProgress > 100 ? 100 : newProgress;
            });
        }, 60);

        return () => clearInterval(interval1);
    }, []);

    // useEffect(() => {
    //     if (progress1 === 100) {
    //         const interval2 = setInterval(() => {
    //             setProgress2((prevProgress) => {
    //                 const newProgress = prevProgress + 1;
    //                 return newProgress > 100 ? 100 : newProgress;
    //             });
    //         }, 60);

    //         return () => clearInterval(interval2);
    //     }
    // }, [progress1]);

    return (
        <Space className="space">
            <Row className="loader">
                <div className="progress-mobile">
                    <Col span={24} className="progress-line">
                        <Progress
                            percent={progress1}
                            showInfo={false}
                            className="progress-line"
                        />
                    </Col>
                    {/* <Col span={12} className="progress-line">
                                <Progress
                                    percent={progress2}
                                    showInfo={false}
                                    className="progress-line"
                                />
                            </Col> */}
                </div>
                <Col xs={24} sm={24} md={12} lg={12} className="left">
                    <Col xs={24} sm={24} md={12} lg={24} className="background">
                        <div className="progress">
                            <Col span={24} className="progress-line">
                                <Progress
                                    percent={progress1}
                                    showInfo={false}
                                    className="progress-line"
                                />
                            </Col>
                            {/* <Col span={12} className="progress-line">
                                <Progress
                                    percent={progress2}
                                    showInfo={false}
                                    className="progress-line"
                                />
                            </Col> */}
                        </div>
                        <h3 className="loading-name">{name}</h3>
                    </Col>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} className="right">
                    <Col span={24}>
                        {/* <Col xs={24} sm={24} md={12} lg={8} className="logo">
                            <img
                                src={appLogo}
                                width="200"
                                height={"120"}
                                alt={appLogo}
                            />
                        </Col> */}
                        <Col span={24}>
                            <h1 className="heading">
                                It's a detail makes a journey perfect
                            </h1>
                            <p className="loading-text">{`${LOCALIZATION.LOADING_RESULTS}...`}</p>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </Space>
    );
}

export default Loading;
