/*
  Actual application starts from this file
*/

import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom"; //https://css-tricks.com/the-hooks-of-react-router/
import Loadable from "react-loadable";

// Antd
import { notification } from "antd";

// Constants
import APP_URL from "./constants/ApplicationUrls";
import { NOTIFICATION_DURATION } from "@timetrack/common/src/constants/General";

// Components
import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loader";
import Page500 from "./components/ErrorPages/Page500";

// Auth Components
import Login from "./components/Authentication/Login";
import Reset_password from "./components/Authentication/resetPassword";
import Confirm_password from "./components/Authentication/ConfirmPassword";

// Styling
import "./assets/sass/style.scss";
//import 'react-toastify/dist/ReactToastify.css';

// Localization
import LOCALIZATION from "@timetrack/common/src/services/LocalizationService";
//import { ToastContainer } from 'react-toastify';

// Package.json
const packageJson = require("../package.json");

// Notification Configuration for whole application
notification.config({
    placement: "topRight",
    duration: NOTIFICATION_DURATION,
    rtl: false,
});

// Base Layout
const BaseLayout = Loadable({
    loader: () => import("./components/BaseLayout"),
    loading: Loading,
});

function App() {
    return (
        <div className={`App`}>
            <BrowserRouter basename={packageJson?.homepage || ""}>
                <Switch>
                    {/* 
            Open Routes Here 
          */}

                    {/* Login */}
                    <Route
                        path={APP_URL.AUTH.LOGIN}
                        name={LOCALIZATION.LOGIN}
                        component={Login}
                    />
                    <Route
                        path={APP_URL.AUTH.RESET_PASSWORD}
                        name={LOCALIZATION.RESET_PASSWORD}
                        component={Reset_password}
                    />

                    {/* Confirm password */}
                    <Route
                        path={APP_URL.AUTH.CONFIRM_PASSWORD}
                        name={LOCALIZATION.CONFIRM_PASSWORD}
                        component={Confirm_password}
                    />
                    {/* Page 500 */}
                    <Route
                        path={APP_URL.ERROR.PAGE500}
                        name={LOCALIZATION.PAGE_500}
                        component={Page500}
                    />

                    {/* Restricted Routes Here */}
                    <PrivateRoute component={BaseLayout} />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
