// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 1rem;
  font-weight: 400;
  color: #1A2659;
}

.multiSelect {
  min-height: 2.1875rem !important;
  min-width: 4.375rem !important;
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/FormElements/MultiSelectButton/style.scss"],"names":[],"mappings":"AAGA;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AAFJ;;AAKA;EACI,gCAAA;EACA,8BAAA;EACA,WAAA;AAFJ","sourcesContent":["@import \"../../../../assets/sass/variables\";\n\n\n.title {\n    font-size: rem(16);\n    font-weight: 400;\n    color: #1A2659;\n}\n\n.multiSelect {\n    min-height: rem(35) !important;\n    min-width: rem(70) !important;\n    margin: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
