import React, { useEffect, useState } from 'react';

// Antd
import { Form } from 'antd';

// Localization
import LOCALIZATION from '@timetrack/common/src/services/LocalizationService';

// Style
import './style.scss';
import { SketchPicker } from 'react-color';

function ColorPicker({
    name, label, defaultValue, value,
    onChange, disabled, required,
    notwrapInForm, validator, validateTrigger
}) {

    const [color, setColor] = useState(value || defaultValue || '#ffffff');

    useEffect(() => {
        // Update parent component when color changes
        !!onChange && onChange(color);
    }, [color, onChange]);

    // Rules
    const rules = [
        ...(required ? [{ required: true, message: LOCALIZATION.REQUIRED }] : []),
        ...(!validator ? [] : [validator])
    ];

    // Color Picker
    const Picker = (
        <SketchPicker
            color={color}
            onChangeComplete={(color) => setColor(color.hex)}
            disableAlpha={disabled}
        />
    );

    return (
        !!notwrapInForm ? (
            <>
                {Picker}
            </>
        ) : (
            <Form.Item
                name={name}
                label={label}
                validateTrigger={validateTrigger || 'onBlur'}
                rules={rules}
            >
                {Picker}
            </Form.Item>
        )
    );
}

export default ColorPicker;
