/*
  Input Text
*/

import React, { useEffect, useState } from 'react';
import { Input, Form } from 'antd';
import LOCALIZATION from '@timetrack/common/src/services/LocalizationService';
import { ANY_STRING_PATTERN } from '@timetrack/common/src/constants/Patterns';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { TextArea } = Input;

function Text({
  name, label, placeholder,
  readOnly, defaultValue,
  onChange, disabled, required,
  onBlur, validator, validateTrigger,
  maxLength, notwrapInForm, useRichTextEditor
}) {
  const [editorValue, setEditorValue] = useState(defaultValue || '');

  useEffect(() => {
    setEditorValue(defaultValue);
  }, [defaultValue]);

  const handleEditorChange = (value) => {
    setEditorValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  let rules = [
    { required: required, message: LOCALIZATION.REQUIRED },
    {
      pattern: "",
      message: LOCALIZATION.SPACE_VALIDATION
    }
  ];

  !!validator && rules.push({ validator });

  const InputText = (
    <TextArea
      placeholder={placeholder || label}
      onChange={onChange || null}
      defaultValue={defaultValue}
      disabled={disabled}
      onBlur={onBlur}
      maxLength={maxLength}
      readOnly={readOnly}
      rows={4}
    />
  );

  const RichTextEditor = (
    <ReactQuill
      placeholder={placeholder || label}
      value={editorValue}
      onChange={handleEditorChange}
      readOnly={readOnly}
      modules={{
        toolbar: {
          container: [
            [{ 'font': [] }, { 'size': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['direction', { 'align': [] }],
            ['link', 'image', 'video', 'formula'],
            ['clean']
          ]
        },
        clipboard: {
          matchVisual: false,
        },
      }}
    />
  );

  return (
    !!notwrapInForm ?
      <>
        {useRichTextEditor ? RichTextEditor : InputText}
      </> :
      <Form.Item
        name={name}
        label={label}
        validateTrigger={validateTrigger || 'onBlur'}
        rules={rules}
      >
        {useRichTextEditor ? RichTextEditor : InputText}
      </Form.Item>
  );
}

export default Text;