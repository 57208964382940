/*
  Application Login Page
*/

import React from "react";
import { useHistory } from "react-router-dom";

// Antd
import { Form, Button } from "antd";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import AuthenticationBase from "..";

// style
import './style.scss'

// Helper
import {
  successNotification,
  errorNotification,
} from "@timetrack/common/src/helpers/Notification";
import { isAdmin, isManager, isSuperAdmin, isHR, isClient } from "@timetrack/common/src/helpers/GeneralHelper";

// Form Elements
import { Text, Password } from "../../Common/FormElements";

// Actions
import { login, getUserPermissions } from "@timetrack/common/src/store/actions/AuthAction";

// Constants
import APP_URL from "../../../constants/ApplicationUrls";
import STATUS_CODES from "@timetrack/common/src/constants/StatusCodes";

// Localization
import LOCALIZATION from "@timetrack/common/src/services/LocalizationService";

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  let formRef = React.createRef();

  // Redux States
  const loading = useSelector((state) => state?.Auth?.loading);

  // On Submit
  const onSubmit = (data) => {
    dispatch(login(data)).then(
      () => {
        //getPermissions();
        successNotification(LOCALIZATION.LOGIN_SUCCESSFULLY);
        history.push(APP_URL.LIST_PROJECT)
        // (isAdmin() || isSuperAdmin() || isManager())
        //   ? history.push(APP_URL.DASHBOARD)
        //   : isHR()
        //     ? history.push(APP_URL.LIST_EMPLOYEE)
        //     : isClient()
        //       ? history.push(APP_URL.LIST_CONTACT)
        //       : history.push(APP_URL.LIST_ALLOCATION)
      },
      (e) => {
        const message = e?.response?.data?.detail
          ? e?.response?.data?.detail
          : e?.response?.status === STATUS_CODES.NOT_FOUND
            ? LOCALIZATION.INVALID_EMAIL_OR_PASSWORD
            : LOCALIZATION.NETWORK_ERROR;

        errorNotification(message);
      }
    );
  };

  const getPermissions = () => {
    dispatch(getUserPermissions()).then(
      ({ flag, response }) => {
        if (!!response?.length) {
          successNotification(LOCALIZATION.LOGIN_SUCCESSFULLY);

          history.push(APP_URL.PROJECT);
        } else {
          errorNotification(LOCALIZATION.NO_PERMISSIONS);
        }
      },
      () => {
        errorNotification(LOCALIZATION.NETWORK_ERROR);
      }
    );
  };

  return (
    <AuthenticationBase loading={loading}>
      <div className="app-logo">
        <p className="sign-in">{LOCALIZATION.SIGN_IN}</p>
        <p className="sign-in-text">{LOCALIZATION.SIGN_IN_TEXT}</p>
      </div>

      <Form
        form={form}
        ref={formRef}
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className=" inputFields">
          <Text
            label={LOCALIZATION.EMAIL}
            className="custom-text"
            name="email"
            required={true}
            placeholder=" "
          />
        </div>

        <div className="inputFields">
          <Password
            label={LOCALIZATION.PASSWORD}
            className="form-control"
            name="password"
            required={true}
            minLength={1}
            placeholder=" "
          />
        </div>

        <div className="loginButtonStyle">
          <Form.Item>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              className="mt-3"
            >
              {LOCALIZATION.SIGN_IN}
            </Button>
          </Form.Item>
        </div>
      </Form>
      {/* <a className="forget-password-link"
        onClick={() => history.push(APP_URL.AUTH.RESET_PASSWORD)}
      // type="text primary"
      >
        {LOCALIZATION.RESET_PASSWORD}?
      </a> */}
    </AuthenticationBase>
  );
}

export default Login;
