export const SECTIONS = {
    DASHBOARD: "dashboard",
    CLIENT: "client",
    PROJECTS: "projects",
    PROJECT: "project",
    EMPLOYEE: "employee",
    EMPLOYEE_SKILLSET: "EmployeeSkillset",
    SKILL_MATRIX: "skillMatrix",
    ALLOCATION: "allocation",
    INVOICING: "invoicing",
    ASSETS: "Assets",
    CONTACT: "Contact",
    TIMESHEET: "timesheet",
    SKILLS: "skills",
    STATUSBOARD: "statusboard",
    LOGOUT: "logout",
    AWS_SERVERS: "awsServers",
    DISCOVERY_SESSION: "discoverySession",
    FEEDBACK: "feedback"
};
