import { LOCAL_STORAGE_KEYS } from '../../constants/General';

export default class ReactNativeStorageService {
  constructor(storage) {
    this.asyncStorage = storage;
  }

  // Set Access Token
  async setAccessToken(accessToken) {
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
      accessToken || ""
    );
  }

  // Get Access Token
  async getAccessToken() {
    try {
      const token = await this.asyncStorage.getItem(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN
      );

      return token;
    } catch (error) {
      return false;
    }
  }

  // Set Refresh Token
  async setRefreshToken(token) {
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
      token || ""
    );
  }

  // Get Refresh Token
  async getRefreshToken() {
    try {
      const token = await this.asyncStorage.getItem(
        LOCAL_STORAGE_KEYS.REFRESH_TOKEN
      );

      return token;
    } catch (error) {
      return false;
    }
  }

  async setUserInfo(user = {}) {
    if (user) {
      user = JSON.stringify(user);
      await this.asyncStorage.setItem(LOCAL_STORAGE_KEYS.USER_INFO, user);
    }
  }

  async getUserInfo() {
    try {
      const data = await this.asyncStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO);
      if (data) {
        return JSON.parse(data);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async deleteLoginData() {
    await this.asyncStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    await this.asyncStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    await this.asyncStorage.removeItem(LOCAL_STORAGE_KEYS.USER_INFO);
  }
}
